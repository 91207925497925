import React, { Fragment, useState, useEffect, useCallback } from "react";
import Img from "gatsby-image";
import classNames from "classnames";

import styles from "./productsImages.module.sass";
import Swiper from "react-id-swiper";
import { Navigation, Controller } from "swiper/dist/js/swiper.esm";

const ProductsImages = ({ images }) => {
    const [swiper, updateSwiper] = useState(null);
    const [currentIndex, updateCurrentIndex] = useState(0);
    const params = {
        modules: [Navigation, Controller],
        getSwiper: updateSwiper,
        containerClass: "swiper-container swiper-container-product",
        shouldSwiperUpdate: true,
        rebuildOnUpdate: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    };

    const updateIndex = useCallback(
        () => updateCurrentIndex(swiper.realIndex),
        [swiper]
    );

    useEffect(() => {
        if (swiper !== null) {
            swiper.on("slideChange", updateIndex);
        }

        return () => {
            if (swiper !== null) {
                swiper.off("slideChange", updateIndex);
            }
        };
    }, [swiper, updateIndex]);

    return (
        <div className={styles.container}>
            <div className={styles.swiper}>
                <Swiper {...params}>
                    {images.map((image, index) => (
                        <div key={index} className={styles.swiperSlide}>
                            <Img fluid={image.childImageSharp.fluid} />
                        </div>
                    ))}
                </Swiper>
            </div>
            <div className={styles.thumbnails}>
                {images.map((image, index) => (
                    <Fragment key={index}>
                        {index < 4 && (
                            <div
                                className={classNames({
                                    [styles.activeThumb]:
                                        index === currentIndex,
                                })}
                                onClick={() => {
                                    if (swiper !== null) {
                                        swiper.slideTo(index);
                                    }
                                }}
                            >
                                <Img
                                    fluid={image?.childImageSharp.fluid}
                                    alt={image.type}
                                />
                            </div>
                        )}

                        {index === 4 && (
                            <div
                                className={classNames({
                                    [styles.activeThumb]: currentIndex >= 4,
                                    [styles.moreThumb]: true,
                                })}
                                onClick={() => {
                                    if (swiper !== null) {
                                        swiper.slideTo(index);
                                    }
                                }}
                            >
                                <Img
                                    fluid={image?.childImageSharp.fluid}
                                    alt={image.type}
                                />
                                <span className={styles.overlay}>
                                    +{images.length - 4}
                                </span>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default ProductsImages;
