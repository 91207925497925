import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Breadcrumb from "../components/BreadCrumb/";
import SiteLayout from "../components/SiteLayout";
import Seo from "../components/Seo";
import ProductContent from "../components/ProductContent";
import Block from "../components/Block";
import SadSpendingBox from "../components/Eshop/SadSpendingBox";

import { measuringViewOfProductDetail } from "../utils/gtm";

const Product = props => {
    const {
        data: {
            allCmProduct: { nodes },
            allCmCategory: { nodes: categories },
        },
    } = props;

    useEffect(() => {
        measuringViewOfProductDetail([nodes[0]]);
    }, []);

    const {
        slug,
        productName,
        description,
        images,
        priceVat,
        specialPrice,
        weight,
        stockState,
        fineness,
        material,
        emitent,
        category,
        itemId,
        featuredImg,
        vat,
        mintage,
    } = nodes[0];

    const productCategories = categories
        .filter(
            category =>
                category.products.findIndex(item => item.itemId === itemId) >= 0
        )
        .sort((a, b) => a.level - b.level);

    const crumbs = [
        {
            label: "E-shop",
            link: "/e-shop",
        },
    ];
    let path = "";

    productCategories.forEach(item => {
        path += `${item.slug}/`;

        crumbs.push({
            label: item.name,
            link: `/e-shop/${path}`,
        });
    });

    crumbs.push({
        label: productName,
        link: `/e-shop/produkt/${slug}`,
    });

    return (
        <SiteLayout location={props.location} eshop>
            <Seo
                title={`${productName}`}
                description={`${description}`}
                image={""}
                url={"/"}
            />
            <Breadcrumb crumbs={crumbs} />
            <ProductContent
                productName={productName}
                images={images}
                featuredImage={featuredImg?.childImageSharp?.fluid}
                description={description}
                price={specialPrice ? specialPrice : priceVat}
                weight={weight}
                stockState={stockState}
                fineness={fineness}
                material={material}
                emitent={emitent}
                itemId={itemId}
                vat={vat}
                category={category}
                mintage={mintage}
            />
            <Block grey={true}>
                <SadSpendingBox
                    images={{
                        spender: props.data.spender.childImageSharp.fixed,
                    }}
                    state={{ prevPath: props.location.pathname }}
                />
            </Block>
        </SiteLayout>
    );
};

export default Product;

export const pageQuery = graphql`
    query GET_PRODUCT_PAGE($id: String!) {
        allCmProduct(filter: { id: { eq: $id } }) {
            nodes {
                itemId
                slug
                productName
                description
                priceVat
                specialPrice
                weight
                stockState
                fineness
                material
                emitent
                category
                mintage
                vat
                featuredImg {
                    childImageSharp {
                        fluid(
                            maxWidth: 318
                            quality: 100
                            traceSVG: { color: "#B29B5B" }
                        ) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                images {
                    id
                    name
                    childImageSharp {
                        fluid(
                            maxWidth: 318
                            quality: 100
                            traceSVG: { color: "#B29B5B" }
                        ) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }
        allCmCategory {
            nodes {
                name
                slug
                products {
                    itemId
                }
            }
        }
        spender: file(relativePath: { eq: "spender.png" }) {
            childImageSharp {
                fixed(width: 388, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }
    }
`;
