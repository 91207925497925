import React, { useState } from "react";
import classNames from "classnames";

import style from "./productContent.module.sass";

import Block from "../../components/Block/";
import Title from "../../components/Title";
import ProductImages from "../../components/ProductImages";
import AddToCart from "../../components/AddToCart";
import InfoIcons from "../../components/InfoIcons";
import InfoTable from "../../components/InfoTable";
import NoVat from "../../components/icons/NoVat";
import Purity from "../../components/icons/Purity";
import Origin from "../../components/icons/Origin";

const descriptionLength = 160;

const ProductContent = ({
    productName,
    images,
    description,
    price,
    weight,
    stockState,
    fineness,
    material,
    emitent,
    itemId,
    featuredImage,
    vat,
    category,
    mintage,
}) => {
    const [descOpen, setDescOpen] = useState(false);

    let icons = [
        {
            name: "origin",
            icon: () => <Origin />,
            label: "Garance původu",
        },
        {
            name: "purity",
            icon: () => <Purity />,
            label: "Garance ryzosti",
        },
    ];

    if (material === "Zlato") {
        icons.unshift({
            name: "noVat",
            icon: () => <NoVat />,
            label: "Osvobozeno od DPH",
        });
    }

    return (
        <>
            <Block leftIndent>
                <Title type={"h1"} color={"gold"} mb={30} mt={30}>
                    {productName}
                </Title>
            </Block>
            <Block>
                <section className={style.container}>
                    <div className={style.gallery}>
                        {images && <ProductImages images={images} />}
                    </div>

                    <div className={style.content}>
                        <p
                            className={classNames({
                                [style.desc]: true,
                                [style.descOpen]: descOpen,
                            })}
                        >
                            {descOpen
                                ? description
                                : description.substr(0, descriptionLength)}
                            {!descOpen &&
                                description.length > descriptionLength && (
                                    <>…</>
                                )}
                        </p>
                        {!descOpen && description.length > descriptionLength && (
                            <button
                                className={style.readMore}
                                onClick={() => setDescOpen(true)}
                            >
                                Celý popis
                                <img
                                    src={require("../../images/icons/readmore-arrow.svg")}
                                    alt=""
                                />
                            </button>
                        )}
                        <p
                            className={classNames({
                                [style.desc]: true,
                                [style.importantInfo]: true,
                            })}
                        >
                            Jedná se o produkt z nabídky České mincovny a.s.,
                            která je prodávajícím. KAPITOL je zde v pozici
                            zprostředkovatele prodeje a prodávajícímu předává osobní údaje potřebné ke vzniku objednávky.
                        </p>
                        <AddToCart
                            price={price}
                            weight={weight}
                            stockState={stockState}
                            itemId={itemId}
                            productName={productName}
                            featuredImage={featuredImage}
                            vat={vat}
                            category={category}
                        />
                        <InfoIcons data={icons} />
                        <InfoTable
                            weight={weight}
                            stockState={stockState}
                            origin={""}
                            fineness={fineness}
                            material={material}
                            emitent={emitent}
                            mintage={mintage}
                        />
                    </div>
                </section>
            </Block>
        </>
    );
};

export default ProductContent;
