import React, { useState, useContext } from "react";
import accounting from "accounting";
import style from "./addToCart.module.sass";
import eshopStyle from "../Eshop/form.module.sass";
import Button from "../Button";
import produce from "immer";

import Modal from "../Modal/Modal";
import { checkoutContext } from "../../context/checkoutContextProvider";
import { measuringAddingProductToShoppingCart } from "../../utils/gtm";
import {
    CM_MAX_ITEM_ORDER_QUANTITY,
    IN_STOCK,
    OUT_OF_STOCK,
} from "../../constants";
import classNames from "classnames";

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 5;
const PRICE_LIMIT = 1000000;

const AddToCart = ({
    price,
    stockState,
    itemId,
    productName,
    featuredImage,
    vat,
    weight,
    category,
}) => {
    const [amount, setAmount] = useState(1);
    const [overOrderLimit, setOverOrderLimit] = useState(false);
    const { cart, setCart } = useContext(checkoutContext);

    /**
     * TODO: do custom hook useAddCart
     */
    const [showModal, setShowModal] = React.useState(false);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const handleSetAmount = newAmount => {
        if (newAmount < MIN_AMOUNT) {
            return setAmount(MIN_AMOUNT);
        }
        if (newAmount > MAX_AMOUNT) {
            setOverOrderLimit(true);
            return setAmount(MAX_AMOUNT);
        }
        setOverOrderLimit(false);
        return setAmount(newAmount);
    };

    const addToCart = () => {
        openModal();
        let product = {
            productName,
            productId: itemId,
            quantity: amount,
            itemPrice: price,
            featuredImage,
            vat,
            category,
        };

        if (typeof window !== "undefined") {
            const nextState = produce(cart, draftState => {
                if (itemId in draftState) {
                    draftState[itemId].quantity = Math.min(
                        parseInt(draftState[itemId].quantity, 10) + amount,
                        CM_MAX_ITEM_ORDER_QUANTITY
                    );
                } else {
                    draftState[itemId] = product;
                }
            });
            measuringAddingProductToShoppingCart(product);
            if (
                cart[itemId] &&
                cart[itemId].quantity + amount > CM_MAX_ITEM_ORDER_QUANTITY
            ) {
                setOverOrderLimit(true);
            }
            setCart(nextState);
        }
    };

    const displayPrice = price * amount;

    const stockStateClass = classNames({
        [style.stockStateGreen]: stockState === IN_STOCK,
        [style.stockStateRed]: stockState !== IN_STOCK,
    });

    return (
        <>
            <div className={style.container}>
                <div className={style.price}>
                    <span className={style.label}>Prodejní cena</span>
                    <p
                        className={
                            displayPrice < PRICE_LIMIT
                                ? style.smallPrice
                                : style.largePrice
                        }
                    >
                        {accounting.formatNumber(displayPrice, 0, " ", ",")}
                        <span className={style.unit}>Kč</span>
                    </p>
                </div>
                <div className={style.gramAndState}>
                    <div className={style.state}>
                        <p className={stockStateClass}>{stockState}</p>
                    </div>

                    <div className={style.priceGram}>
                        <span className={style.label}>Cena za 1g</span>
                        <p>
                            {accounting.formatNumber(
                                weight
                                    ? Math.round(
                                          price /
                                              parseFloat(weight.split(" ")[0])
                                      )
                                    : price,
                                0,
                                " ",
                                ","
                            )}{" "}
                            Kč
                        </p>
                    </div>
                </div>

                {stockState !== OUT_OF_STOCK && (
                    <>
                        <div className={style.buttonWrapper}>
                            <div className={style.amount}>
                                <div className={eshopStyle.productAmount}>
                                    <span
                                        className={eshopStyle.minus}
                                        onClick={() =>
                                            handleSetAmount(amount - 1)
                                        }
                                    >
                                        -
                                    </span>
                                    <span className={eshopStyle.amount}>
                                        {amount}&nbsp;Ks
                                    </span>
                                    <span
                                        className={eshopStyle.plus}
                                        onClick={() =>
                                            handleSetAmount(amount + 1)
                                        }
                                    >
                                        +
                                    </span>
                                </div>
                            </div>
                            <div className={style.button}>
                                <Button
                                    onClick={() => addToCart()}
                                    data-test="add-to-cart"
                                >
                                    Do košíku
                                </Button>
                            </div>
                        </div>
                    </>
                )}
                {overOrderLimit && (
                    <span
                        className={classNames({
                            [style.alert]: true,
                            [style.alertMessage]: true,
                        })}
                    >
                        Lze objednat maximálně {CM_MAX_ITEM_ORDER_QUANTITY} kusů
                        od jednoho produktu.
                    </span>
                )}
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={() => closeModal()}
                closeTimeoutMS={200}
                overOrderLimit={overOrderLimit}
            />
        </>
    );
};

export default AddToCart;
