import React from "react";

import style from "./infoTable.module.sass";

const InfoTable = ({
    material,
    weight,
    mintage,
    fineness,
    origin,
    emitent,
    stockState,
}) => (
    <table className={style.infoTable}>
        <tbody>
            {material && (
                <tr>
                    <td>Materiál</td>
                    <td>{material}</td>
                </tr>
            )}
            {mintage && (
                <tr>
                    <td>Emisní náklad</td>
                    <td>{mintage}</td>
                </tr>
            )}
            {weight && (
                <tr>
                    <td>Hmotnost</td>
                    <td>{weight}</td>
                </tr>
            )}
            {fineness && (
                <tr>
                    <td>Ryzost</td>
                    <td>{fineness}</td>
                </tr>
            )}
            {origin && (
                <tr>
                    <td>Země původu</td>
                    <td>{origin}</td>
                </tr>
            )}
            {emitent && (
                <tr>
                    <td>Výrobce</td>
                    <td>{emitent}</td>
                </tr>
            )}
            {stockState && (
                <tr>
                    <td>Dostupnost</td>
                    <td>{stockState}</td>
                </tr>
            )}
        </tbody>
    </table>
);

export default InfoTable;
